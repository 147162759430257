import { View } from 'components/Core/View'
import { getAccounts, Account } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom';

export const AccountView = () => {
    
    const columns: ColumnsType<Account> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
        },
        {
            title: 'Number',
            dataIndex: 'number',
        },
        {
            title: 'Company',
            dataIndex: 'billingCompany',
        },
        {
            title: 'Address 1',
            dataIndex: 'billingAddress1',
        },
        {
            title: 'Address 2',
            dataIndex: 'billingAddress2',
        },
        {
            title: 'City',
            dataIndex: 'billingCity',
        },
        {
            title: 'State',
            dataIndex: 'billingState',
        },
        {
            title: 'ZIP Code',
            dataIndex: 'billingZipCode',
        },
    ];

    return (
        <View title='Accounts' columns={columns} getData={getAccounts} />
    )
}