import Button from "antd/lib/button"
import Form from "antd/lib/form"
import { useForm } from "antd/lib/form/Form"
import { Col, Row } from "antd/lib/grid"
import Input from "antd/lib/input"
import Select from "antd/lib/select"
import Space from "antd/lib/space"
import Table, { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { AvailablePhoneNumber, searchProviderPhoneNumbers } from "services/DataService"

type PhoneNumberLookupProps = {
    providerId?: string
    onSelect: (phoneNumber?: string) => void
}
export const PhoneNumberLookup = ({ providerId, onSelect }: PhoneNumberLookupProps) => {

    const [searching, setSearching] = useState(false);
    const [searchError, setSearchError] = useState<string>()

    const [phoneNumbers, setPhoneNumbers] = useState<AvailablePhoneNumber[]>()

    const columns: ColumnsType<AvailablePhoneNumber> = [
        {
            title: 'Phone Number',
            dataIndex: 'value',
        },
        {
            title: 'City',
            dataIndex: 'locale',
        },
        {
            title: 'State',
            dataIndex: 'region',
        },
    ];

    const [form] = useForm()

    useEffect(() => {
        setPhoneNumbers(undefined)
        form.resetFields()
    }, [providerId, form])

    return (
        <>
            <Form form={form} name='search' layout='vertical' initialValues={{ country: 'US' }} onFinish={async (data) => {
                setSearchError(undefined)
                setSearching(true)
                try {
                    setPhoneNumbers(undefined)
                    var result = await searchProviderPhoneNumbers(providerId!, data)
                    setPhoneNumbers(result)
                }
                catch {
                    setSearchError("There was an error searching. Please try again.")
                }
                finally {
                    setSearching(false)
                }
            }}>
                <Row gutter={[16, 0]}>
                    <Col xs={24} md={5}>
                        <Form.Item label="Country" name="country">
                            <Select>
                                <Select.Option value='US'>US</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={9}>
                        <Form.Item label="Area Code" name="areaCode">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item label="Contains" name="contains">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} style={{ padding: 8 }}>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType='submit' loading={searching}>Search</Button>
                                <Button type='ghost' disabled={searching} onClick={() => { setSearchError(undefined); setPhoneNumbers(undefined); onSelect(undefined); form.resetFields() }}>Reset</Button>
                                {searchError && <span className='error'>{searchError}</span>}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Form name='searchResults' layout='vertical'>
                <Row gutter={[16, 0]}>
                    <Form.Item name="value" hidden><Input /></Form.Item>
                    {phoneNumbers &&
                        <Col xs={24}>
                            <Table columns={columns} size='middle' dataSource={phoneNumbers} rowKey='value' rowSelection={{ type: 'radio', onSelect: (x) => onSelect(x.value) }} pagination={false} loading={{ spinning: searching }} />
                        </Col>
                    }
                </Row>
            </Form>
        </>
    )
}