import { Alert, Card, Col, PageHeader, Row, Select, Space, Spin, Statistic } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import { getAnalytics } from 'services/DataService';
import { Analytics } from 'services/types';
import { MessagesChart } from './Charts/MessagesChart';
import { SpendingChart } from './Charts/SpendingChart';
import WarningFilled from '@ant-design/icons/WarningFilled'
import { Content } from 'antd/lib/layout/layout';
import { CommandBar } from '@fluentui/react/lib/CommandBar';

const OptGroup = Select.OptGroup
const Option = Select.Option

export type Grouping = 'daily' | 'monthly'

export const AnalyticsDashboard = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string>()

    const [data, setData] = useState<Analytics>()

    const [minDate, setMinDate] = useState<Date>(new Date())
    const [maxDate, setMaxDate] = useState<Date>(new Date())

    const [filter, setFilter] = useState<string>("last7Days")
    const [grouping, setGrouping] = useState<Grouping>('daily')

    const refreshData = useCallback(() => {
        setError(undefined)
        setIsLoading(true)
        setData(undefined)
        var [min, max] = setDates(filter)
        getAnalytics({
            groupBy: grouping,
            min: format(min, 'yyyy-MM-dd'),
            max: format(max, 'yyyy-MM-dd')
        })
            .then((data) => {
                setData(data)
            })
            .catch(() => {
                setError("Failed to load the dashboard.")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [filter, grouping]);

    useEffect(() => {
        refreshData()
    }, [refreshData])

    const setDates = (filter: string): [Date, Date] => {
        let now = new Date()

        let min = new Date(now)
        let max = new Date(now)

        switch (filter) {
            case "last7Days":
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7 + 1)
                break;
            case "last30Days":
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30 + 1)
                break;
            case "last90Days":
                min = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 90 + 1)
                break;
            case "thisMonth":
                min = new Date(now.getFullYear(), now.getMonth(), 1)
                max = new Date(now.getFullYear(), now.getMonth() + 1, 0)
                break;
            case "thisYear":
                min = new Date(now.getFullYear(), 0, 1)
                max = new Date(now.getFullYear() + 1, 0, 0)
                break;
            case "lastMonth":
                min = new Date(now.getFullYear(), now.getMonth() - 1, 1)
                max = new Date(now.getFullYear(), now.getMonth(), 0)
                break;
            case "last3Months":
                min = new Date(now.getFullYear(), now.getMonth() - 3, 1)
                max = new Date(now.getFullYear(), now.getMonth(), 0)
                break;
            case "last6Months":
                min = new Date(now.getFullYear(), now.getMonth() - 6, 1)
                max = new Date(now.getFullYear(), now.getMonth(), 0)
                break;
            case "last12Months":
                min = new Date(now.getFullYear(), now.getMonth() - 12, 1)
                max = new Date(now.getFullYear(), now.getMonth(), 0)
                break;
        }

        setMinDate(min)
        setMaxDate(max)

        return [min, max]
    }

    const onChangeFilter = (value: string) => {
        setFilter(value)
    }
    const onChangeGrouping = (value: Grouping) => {
        setGrouping(value)
    }

    const renderCommands = useMemo(() => (
        <CommandBar items={[{
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => refreshData()
        }]} />
    ), [refreshData]);

    const renderMessages = useMemo(() => (
        <>
            {error ? <Alert type='error' showIcon message={error} onClose={() => setError(undefined)} /> : null}
        </>
    ), [error])

    return (
        <>
            {renderCommands}
            {renderMessages}
            <PageHeader title="Analytics" />
            <Content style={{ padding: '16px 24px' }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Spin className="spinner" spinning={isLoading} size="large">
                            <Space direction='vertical' style={{ paddingBottom: 16 }}>
                                <Select defaultValue={filter} style={{ width: 200 }} onChange={onChangeFilter} >
                                    <OptGroup label="Relative Dates">
                                        <Option value="last7Days">Last 7 Days</Option>
                                        <Option value="last30Days">Last 30 Days</Option>
                                        <Option value="last90Days">Last 90 Days</Option>
                                    </OptGroup>
                                    <OptGroup label="Calendar Months">
                                        <Option value="thisMonth">This Month</Option>
                                        <Option value="thisYear">This Year</Option>
                                        <Option value="lastMonth">Last Month</Option>
                                        <Option value="last3Months">Last 3 Months</Option>
                                        <Option value="last6Months">Last 6 Months</Option>
                                        <Option value="last12Months">Last 12 Months</Option>
                                    </OptGroup>
                                </Select>
                                <Select defaultValue={grouping} style={{ width: 200 }} onChange={onChangeGrouping} >
                                    <Option value="daily">Daily</Option>
                                    <Option value="monthly">Monthly</Option>
                                </Select>
                            </Space>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12} md={6}>
                                    <Card>
                                        <Statistic title="Total Spend" value={data?.totalSpend ?? '--'} precision={2} prefix="$" />
                                    </Card>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <Card>
                                        <Statistic title="Incoming" value={data?.incomingMessages ?? '--'} precision={0} />
                                    </Card>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <Card>
                                        <Statistic title="Outgoing" value={data?.outgoingMessages ?? '--'} precision={0} />
                                    </Card>
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    <Card>
                                        <Statistic title="Failed" value={data?.failedMessages ?? '--'} precision={0} suffix={(data?.failedMessages ?? 0) > 0 && <WarningFilled style={{ color: '#d43064' }} />} />
                                    </Card>
                                </Col>
                                <Col md={24} xl={12}>
                                    <Card title="Messages">
                                        <MessagesChart grouping={grouping} min={minDate} max={maxDate} messages={data?.messages ?? []} />
                                    </Card>
                                </Col>
                                <Col md={24} xl={12}>
                                    <Card title="Spending">
                                        <SpendingChart grouping={grouping} min={minDate} max={maxDate} spending={data?.spending ?? []} />
                                    </Card>
                                </Col>
                            </Row>
                        </Spin>
                    </Col>
                </Row>
            </Content>
        </>
    )
}