import React from 'react'
import { View } from 'components/Core/View'
import { Link } from 'react-router-dom'
import { getEnvironments } from 'services/DataService'
import { Environment } from 'services/types'
import { ColumnsType } from 'antd/lib/table'
import { EnvironmentQuickForm } from './EnvironmentQuickForm'

export const EnvironmentView = () => {

    const columns: ColumnsType<Environment> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filters: [
                {
                    text: 'Production',
                    value: 'Production',
                },
                {
                    text: 'Sandbox',
                    value: 'Sandbox',
                },
            ],
            filterMultiple: false,
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
    ];

    return (
        <View title="Environments" getData={getEnvironments} columns={columns} newForm={EnvironmentQuickForm} />
    )
}