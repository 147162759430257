import Button from "antd/lib/button"
import Form from "antd/lib/form"
import { Col, Row } from "antd/lib/grid"
import Input from "antd/lib/input"
import Space from "antd/lib/space"
import { useState } from "react"
import { createAccount, createCurrentUser } from "services/DataService"

type CreateAccountStepProps = {
    onCompleted: () => void
}

export const CreateAccountStep = ({ onCompleted }: CreateAccountStepProps) => {

    const [accountSaving, setAccountSaving] = useState(false)
    const [error, setError] = useState<string>()

    return (
        <Space direction='vertical' size="large">
            <span>In order to get started, we need a few details about your company. This will be used for billing purposes.</span>
            <Form layout='vertical' onFinish={async (data) => {
                setAccountSaving(true)
                try {
                    await createCurrentUser()
                    const accountId = await createAccount({ name: "Default", ...data })
                    window.localStorage.setItem("accountId", accountId)
                    onCompleted()
                }
                catch {
                    setError("There was an error creating the account. Please try again.")
                }
                finally {
                    setAccountSaving(false)
                }
            }}>
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item label="Company" name="billingCompany" rules={[{ required: true, message: 'Please enter a company name.' }]}>
                            <Input autoComplete="billing organization" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Address 1" name="billingAddress1" rules={[{ required: true, message: 'Please enter an address.' }]}>
                            <Input autoComplete="billing address-line1" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Address 2" name="billingAddress2">
                            <Input autoComplete="billing address-line2" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="City" name="billingCity" rules={[{ required: true, message: 'Please enter a city.' }]}>
                            <Input autoComplete="billing address-level2" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="State" name="billingState" rules={[{ required: true, message: 'Please enter a state.' }]}>
                            <Input autoComplete="billing address-level1" maxLength={2} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="ZIP Code" name="billingZipCode" rules={[{ required: true, message: 'Please enter a ZIP code.' }]}>
                            <Input autoComplete="billing postal-code" maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} style={{ paddingTop: 16 }}>
                        <Button type="primary" htmlType='submit' loading={accountSaving} onClick={() => setError(undefined)}>Next</Button>
                        {error && <span className='error'>{error}</span>}
                    </Col>
                </Row>
            </Form>
        </Space >
    )
}