import { Layout, Result } from "antd"

export const Error500 = () => {
    return (
        <Layout style={{ height: '100%', justifyContent: 'center' }}>
            <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong." />
        </Layout >
    )
}