import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Drawer, Layout, PageHeader, Row, Form as AntForm, FormProps as AntFormProps, Spin, Input, Modal, Alert } from "antd";
import { Entity } from "services/DataService";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { useNavigate } from "react-router";

type BaseFormProps<T> = {
    title?: string
    form?: FormInstance
    getData?: () => Promise<Partial<T>>
    onSubmit?: (data: T) => Promise<void>
    actions?: ICommandBarItemProps[]
    getMessages?: (data: Partial<T>) => React.ReactNode
    children: React.ReactElement<SectionProps> | React.ReactElement<SectionProps>[]
    header?: React.ReactNode
    footer?: React.ReactNode
}

type MainFormProps<T> = BaseFormProps<T> & {
    isQuickForm?: false
}

type QuickFormProps<T> = BaseFormProps<T> & {
    isQuickForm: true
    visible: boolean
    onClose: () => void
}

type FormProps<T> = MainFormProps<T> | QuickFormProps<T>

export const Form = <T extends Entity>(props: FormProps<T>) => {

    const navigate = useNavigate()
    const [form] = useForm<T>(props.form)
    const [data, setData] = useState<Partial<T>>()
    const [error, setError] = useState<string>()
    const [isLoading, setIsLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [saveEnabled, setSaveEnabled] = useState(false)

    const { getData, onSubmit } = props

    const refreshData = useCallback(() => {
        setError(undefined)
        setIsLoading(true)
        getData && getData().then(data => {
            setData(data);
        }).catch((ex) => {
            setData(undefined)
            Modal.error({
                title: 'Error',
                content: 'There was an error loading the data.',
                centered: true,
                onOk: () => navigate(-1)
            });
        }).finally(() => {
            setIsLoading(false);
        })
    }, [getData, navigate])

    useEffect(() => {
        if (getData) {
            refreshData()
        } else {
            setIsLoading(false)
        }
    }, [getData, refreshData])

    useEffect(() => {
        if (data) {
            form.resetFields()
            setIsLoading(false)
        }
    }, [form, data])

    
    const onValuesChanged = useCallback(() => {
        const isFieldsTouched = form.isFieldsTouched()
        setSaveEnabled(isFieldsTouched)
    }, [form])

    const fieldsValue = form.getFieldsValue()

    useEffect(() => {
        onValuesChanged()
    }, [fieldsValue, onValuesChanged])

    const items: ICommandBarItemProps[] = []

    if (onSubmit) {
        items.push({
            key: 'save',
            text: 'Save',
            iconProps: { iconName: 'Save' },
            disabled: !saveEnabled,
            onClick: () => {
                setIsSaving(true)
                form.submit()
            }
        })
    }

    if (getData) {
        items.push({
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => refreshData()
        })
    }

    const onFinish = (data: T) => {
        setError(undefined)
        props.onSubmit && props.onSubmit(data)
            .then(() => {
                setSaveEnabled(false)
            })
            .catch(() => {
                setError("Failed to save the record. Please try again.")
                setSaveEnabled(true);
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    const onFinishFailed = () => {
        setIsSaving(false)
    }

    const renderCommands = (
        <CommandBar items={items.concat(props.actions || [])} />
    )

    const renderMessages = (
        <>
            {error ? <Alert type='error' showIcon message={error} onClose={() => setError(undefined)} /> : null}
            {props.getMessages && data ? props.getMessages(data) : null}
        </>
    )

    const renderPageHeader = (
        <>
            {!props.isQuickForm && props.title && <PageHeader title={props.title} style={{ paddingBottom: 0 }} />}
        </>
    )

    const formProps: AntFormProps = {
        form: form,
        initialValues: data,
        labelAlign: "left",
        labelWrap: true,
        colon: false,
        size: "middle",
        onFinish: onFinish,
        onFinishFailed: onFinishFailed,
        onValuesChange: onValuesChanged,
        labelCol: { xs: 14, md: 10, lg: 8, xl: 7 },
        wrapperCol: { xs: 10, md: 14, lg: 16, xl: 17 },
    }

    const renderForm = (
        <>
            {renderCommands}
            {renderMessages}
            <Spin spinning={isLoading || isSaving} size="large" tip={isLoading ? "Loading..." : "Saving..."}>
                {renderPageHeader}
                <Layout>
                    <Layout.Content style={{ padding: '24px' }}>
                        <Row gutter={[16, 16]}>
                            {props.header}
                        </Row>
                        <AntForm {...formProps} name="main">
                            <Row gutter={[16, 16]}>
                                <Field hidden name="id"><Input /></Field>
                                {props.children}
                            </Row>
                        </AntForm>
                        <Row gutter={[16, 16]}>
                            {props.footer}
                        </Row>
                    </Layout.Content>
                </Layout>
            </Spin>
        </>
    )

    if (props.isQuickForm) {
        return (
            <Drawer title={props.title} width={500} onClose={props.onClose} visible={props.visible} bodyStyle={{ backgroundColor: '#f0f2f5', padding: 0, margin: 0 }} >
                {renderForm}
            </Drawer>
        )
    } else {
        return renderForm
    }
}

type SectionProps = {
    title: string
    cols?: number
    children?: React.ReactNode
}

export const Section = ({ title, cols = 24, children }: SectionProps) => {
    return (
        <Col span={cols}>
            <Card title={title} size="small" bodyStyle={{ padding: '1rem' }} headStyle={{ padding: '.2rem 1rem' }}>
                {children}
            </Card>
        </Col>
    );
}

export const Field = AntForm.Item