import React, { useCallback, useEffect, useState } from "react";
import { Col, Layout, PageHeader, Row, TablePaginationConfig, Table, Empty } from "antd";
import { Entity, Collection } from "services/DataService";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'

type BaseViewProps<T> = {
    title?: string
    getData: (data?: { [x: string]: string }) => Promise<Collection<T>>
    columns: ColumnsType<T>
    actions?: ICommandBarItemProps[]
    newForm?: any
}

type MainViewProps<T> = BaseViewProps<T> & {
    isQuickView?: false
}

type QuickViewProps<T> = BaseViewProps<T> & {
    isQuickView: true
}

type ViewProps<T> = MainViewProps<T> | QuickViewProps<T>

export const View = <T extends Entity>(props: ViewProps<T>) => {

    const [data, setData] = useState<Collection<T>>()
    const [isLoading, setIsLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);

    const [error, setError] = useState<string>()

    const { getData } = props

    const refreshData = useCallback((params?: { [x: string]: string }) => {
        setError(undefined)
        setIsLoading(true)
        getData(params).then(data => {
            setData(data);
        }).catch((ex) => {
            setData(undefined)
            setError('There was an error loading the data.')
        }).finally(() => {
            setIsLoading(false);
        })
    }, [getData])

    useEffect(() => {
        refreshData()
    }, [refreshData])

    const items: ICommandBarItemProps[] = [];
    if (props.newForm) {
        items.push({
            key: 'new',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: () => setShowModal(true)
        })
    }
    items.push({
        key: 'refresh',
        text: 'Refresh',
        iconProps: { iconName: 'Refresh' },
        onClick: () => refreshData()
    })

    const pagingationProps: TablePaginationConfig = {
        current: data?.page,
        total: data?.totalCount,
        pageSize: data?.pageSize,
        defaultPageSize: 10,
        showSizeChanger: true,
        size: 'default'
    }

    const renderCommands = () => (
        <CommandBar items={items.concat(props.actions || [])} className={props.isQuickView ? "viewCommands" : undefined} />
    )

    const renderPageHeader = () => (
        <>
            {!props.isQuickView && props.title && <PageHeader title={props.title} style={{ paddingBottom: 0 }} />}
        </>
    )

    const onChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T> | SorterResult<T>[]) => {

        let params: { [x: string]: string } = {}

        // Add pagination
        if (pagination.current && pagination.current > 1) {
            params['page'] = pagination.current.toString()
        }
        if (pagination.pageSize !== pagination.defaultPageSize && pagination.pageSize) {
            params['pageSize'] = pagination.pageSize.toString()
        }

        // Add sorting
        let sort = sorter as SorterResult<T>
        if (sort.field && sort.order) {
            params['sort'] = sort.field + (sort.order === "ascend" ? "+" : "-")
        }

        // Add filters
        Object.keys(filters).filter(x => filters[x]).forEach(field => {
            if (filters && filters[field]) {
                params[field] = filters[field]!.join(",")
            }
        })

        refreshData(params)
    }

    const renderTable = () => (
        <>
            {renderCommands()}
            {renderPageHeader()}
            <Layout.Content style={{ padding: props.isQuickView ? 0 : 24 }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Table
                            locale={{ emptyText: error ? <Empty image={<CloseCircleOutlined style={{ color: 'rgba(255,0,0,.7)', fontSize: 36 }} />} description={error} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                            columns={props.columns}
                            rowKey="id"
                            dataSource={data?.value}
                            rowSelection={{}}
                            bordered={props.isQuickView}
                            size={props.isQuickView ? "small" : "middle"}
                            pagination={pagingationProps}
                            loading={isLoading}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
            </Layout.Content>
            {showModal ? React.createElement(props.newForm, {
                visible: showModal, onClose: () => {
                    setShowModal(false);
                    refreshData();
                }
            }) : null}
        </>
    )

    return renderTable()
}
