import { Select } from "antd";
import { Form, Section, Field } from "components/Core/Form";
import { useEffect, useState } from "react";
import { AvailableEnvironment, createEnvironment, getAvailableEnvironments } from "services/DataService";

type EnvironmentQuickFormProps = {
    visible: boolean
    onClose: () => void
}

export const EnvironmentQuickForm = ({ visible, onClose }: EnvironmentQuickFormProps) => {

    const [environments, setEnvironments] = useState<AvailableEnvironment[]>()

    useEffect(() => {
        if (visible) {
            getAvailableEnvironments().then(data => setEnvironments(data.value))
        }
    }, [visible])

    return (
        <Form isQuickForm title="New Environment" onSubmit={(data) => {
            return createEnvironment(data).then(onClose);
        }} visible={visible} onClose={onClose}>
            <Section title="Details">
                <Field name="instanceId" label="Environment" rules={[{ required: true, message: 'Please choose an environment.' }]}>
                    <Select>
                        {environments?.map(environment => <Select.Option key={environment.instanceId} value={environment.instanceId}>{environment.instanceName}</Select.Option>)}
                    </Select>
                </Field>
            </Section>
        </Form>
    )
}