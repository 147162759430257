import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthConfig, AuthProvider } from 'services/auth';
import { PublicClientApplication } from '@azure/msal-browser';
import { App } from './App';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
initializeIcons();

export const instance = new PublicClientApplication(AuthConfig);

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider instance={instance}>
      <App />
    </AuthProvider>
  </BrowserRouter>
);