import { View } from 'components/Core/View'
import { getUsers, User } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { UserQuickForm } from './UserQuickForm'

export const UserView = () => {

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
        },
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
    ];

    return (
        <View title='Users' columns={columns} getData={getUsers} newForm={UserQuickForm} />
    )
}