import Button from "antd/lib/button";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Select from "antd/lib/select";
import Space from "antd/lib/space"
import { useEffect, useState } from "react";
import { PhoneNumberLookup } from "screens/PhoneNumber/PhoneNumberLookup";
import { acquireProviderPhoneNumber, getEnvironments, getProviders } from "services/DataService";
import { Provider } from "services/types/Provider";

type SelectPhoneNumberStepProps = {
    onCompleted: () => void
}

export const SelectPhoneNumberStep = ({ onCompleted }: SelectPhoneNumberStepProps) => {

    const [environmentId, setEnvironmentId] = useState<string>();
    const [providers, setProviders] = useState<Provider[]>();
    const [providerId, setProviderId] = useState<string>();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();
    const [phoneNumberSaving, setPhoneNumberSaving] = useState(false);
    const [error, setError] = useState<string>()

    const onSelect = (phoneNumber?: string) => {
        setError(undefined)
        setSelectedPhoneNumber(phoneNumber)
    }

    useEffect(() => {
        getProviders().then(data => setProviders(data.value))
        getEnvironments().then(data => setEnvironmentId(data.value[0].id))
    }, [])

    const onNext = async () => {
        setError(undefined)
        setPhoneNumberSaving(true);
        try {
            await acquireProviderPhoneNumber(providerId!, {
                providerId: providerId,
                environmentId: environmentId,
                value: selectedPhoneNumber,
            })
            onCompleted()
        }
        catch {
            setError("There was an error acquiring the phone number. Please try again.")
        }
        finally {
            setPhoneNumberSaving(false)
        }
    }

    return (
        <Space direction='vertical'>
            <span>Now that your environment is linked, you need to choose a phone number to use.</span>

            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col xs={24} style={{ paddingTop: 16 }}>
                        <Form.Item name="instanceId">
                            <Select placeholder="Please choose a provider..." onSelect={(value: string) => { setProviderId(value) }}>
                                {providers?.map(provider => <Select.Option key={provider.id} value={provider.id}>{provider.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <PhoneNumberLookup providerId={providerId} onSelect={onSelect} />
            <Form layout='vertical' onFinish={onNext}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Button type="primary" htmlType='submit' disabled={selectedPhoneNumber === undefined} loading={phoneNumberSaving} onClick={() => setError(undefined)}>Next</Button>
                        {error && <span className='error'>{error}</span>}
                    </Col>
                </Row>
            </Form>
        </Space>
    )
}