import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import Layout, { Content, Header } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import Menu from 'antd/lib/menu'
import { Avatar, Dropdown, MenuProps, Spin, Typography } from 'antd';
import { BsBox, BsChat, BsCloud, BsGrid, BsPerson, BsTelephone } from 'react-icons/bs'
import { Error500 } from 'screens'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { UserOutlined } from '@ant-design/icons'
import { Context, getContext } from 'services/DataService'

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode) {
  return { label, key, icon };
}


type AppContextProps = {
  isFailed: boolean
  isLoaded: boolean
  context?: Context
  refresh: () => Promise<void>
}

const AppContext = createContext<AppContextProps>({
  isFailed: false,
  isLoaded: false,
  refresh: async () => { },
});

export const useAppContext = () => useContext(AppContext);

export const Base = () => {

  const { instance } = useMsal()

  const isAuthenticated = useIsAuthenticated();

  const [context, setContext] = useState<Context>();
  const [isFailed, setIsFailed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const refresh = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const context = await getContext()
        setContext(context)
      } catch {
        setIsFailed(true)
        setIsLoaded(true)
      }
    } else {
      return Promise.resolve()
    }
  }, [isAuthenticated])

  useEffect(() => {
    refresh();
  }, [refresh])

  useEffect(() => {
    if (context) {
      if (context.defaultAccount) {
        window.localStorage.setItem("accountId", context.defaultAccount.id);
      }
      setIsLoaded(true);
    }
  }, [context])

  const isSetupComplete = context?.hasAccounts && context.hasEnvironments && context.hasPhoneNumbers;

  const items = [
    getItem(isSetupComplete ? 'Dashboard' : 'Setup', '/admin', <BsGrid />),
  ];

  if (isSetupComplete) {
    items.push(
      getItem('Accounts', '/admin/accounts', <BsBox />),
      getItem('Providers', '/admin/providers', <BsCloud />),
      getItem('Phone Numbers', '/admin/phoneNumbers', <BsTelephone />),
      getItem('Environments', '/admin/environments', <BsBox />),
      getItem('Messages', '/admin/messages', <BsChat />),
      getItem('Users', '/admin/users', <BsPerson />),
    )
  }

  const navigate = useNavigate()

  const pathName = useLocation().pathname;
  const index = pathName.indexOf("/", 7);

  const currentPath = index === -1 ? pathName : pathName.substring(0, index)

  const onClick: MenuProps['onClick'] = e => {
    navigate(e.key)
  };

  return (
    <AppContext.Provider value={{ isFailed, isLoaded, context, refresh }}>
      <Layout style={{ minHeight: '100vh' }}>

        <Sider breakpoint="lg">
          <Typography.Title level={1} className="title">Admin Center</Typography.Title>
          <Menu theme="dark" mode="inline" selectedKeys={[currentPath]} items={items} onClick={onClick} />
        </Sider>

        <Layout>
          <Header className="header" >
            <div />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="logout" onClick={() => instance.logoutRedirect()}>Logout</Menu.Item>
                </Menu>}
              trigger={["click"]}
              overlayStyle={{ border: '1px solid #c2c2c2', boxShadow: '1px' }}>
              <div onClick={e => e.preventDefault()}>
                <Avatar size="large" style={{ background: '#30D4A0', fontWeight: '500', fontSize: 16 }} icon={<UserOutlined />} />
              </div>
            </Dropdown>
          </Header>
          <Content>
            {isLoaded || !isAuthenticated ? (isFailed ? <Error500 /> : <Layout><Outlet /></Layout>) : <Spin className="spinner" spinning={true} size="large" />}
          </Content>
        </Layout>

      </Layout >
    </AppContext.Provider>
  )
}