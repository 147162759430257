import "chart.js/auto";
import 'chartjs-adapter-date-fns';
import { Bar } from "react-chartjs-2";
import { SpendingSummary } from "services/types";
import parse from "date-fns/parse";
import { ChartOptions } from "chart.js/auto";

type SpendingChartProps = {
    grouping: 'daily' | 'monthly'
    min?: Date,
    max?: Date,
    spending: SpendingSummary[]
}

export const SpendingChart = ({ grouping: aggregation, min, max, spending }: SpendingChartProps) => {

    const options: ChartOptions<"bar"> = {
        plugins: {
            legend: {
                position: 'bottom'
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    maxRotation: 0,
                },
                type: 'time',
                time: {
                    unit: aggregation === 'daily' ? 'day' : 'month',
                    round: aggregation === 'daily' ? 'day' : 'month',
                },
                min: min?.getTime(),
                max: max?.getTime(),
            },
            y: {
                stacked: true,
                ticks: {
                    callback: function (value) {
                        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
                    },
                    precision: 2,
                }
            },
        }
    }

    const data = {
        datasets: [
            {
                label: 'Messages',
                data: spending.map(m => {
                    return {
                        x: parse(m.group, aggregation === 'daily' ? 'yyyy-MM-dd' : 'yyyy-MM', new Date(0)),
                        y: m.messages,
                    }
                }),
                backgroundColor: '#30d4a0'
            },
        ]
    }

    return (
        <Bar options={options} data={data} />
    )
}