import { Input, Radio, Select } from "antd";
import { Form, Section, Field } from "components/Core/Form";
import { useEffect, useState } from "react";
import { Collection, createMessage, Environment, getEnvironments } from "services/DataService";

type MessageQuickFormProps = {
    visible: boolean
    onClose: () => void
}

export const MessageQuickForm = ({ visible, onClose }: MessageQuickFormProps) => {

    const [environments, setEnvironments] = useState<Collection<Environment>>()

    useEffect(() => {
        getEnvironments().then(setEnvironments)
    }, [])

    return (
        <Form isQuickForm title="New Message" onSubmit={(data) => {
            return createMessage(data).then(onClose);
        }} visible={visible} onClose={onClose}>
            <Section title="Details">
                <Field name="environmentId" label="Environment" rules={[{ required: true, message: 'Please choose an environment.' }]}>
                    <Select>
                        {environments?.value?.map(environment => <Select.Option key={environment.id} value={environment.id}>{environment.name}</Select.Option>)}
                    </Select>
                </Field>
                <Field name="channel" label="Channel" initialValue="text">
                    <Radio.Group>
                        <Radio value="text">Text</Radio>
                        <Radio value="chat">Chat</Radio>
                    </Radio.Group>
                </Field>
                <Field name="direction" label="Direction" initialValue="outgoing">
                    <Radio.Group>
                        <Radio value="outgoing" defaultChecked>Outgoing</Radio>
                        <Radio value="incoming">Incoming</Radio>
                    </Radio.Group>
                </Field>
                <Field name="source" label="Source"><Input /></Field>
                <Field name="target" label="Target"><Input /></Field>
                <Field name="content" label="Content"><Input /></Field>
            </Section>
        </Form>
    )
}