import { useParams } from 'react-router';
import { Input } from 'antd';
import { Form, Section, Field } from 'components/Core/Form';
import { useCallback } from 'react';
import { getAccountById, updateAccount } from 'services/DataService';

export const AccountForm = () => {

    const { accountId } = useParams();

    const getData = useCallback(() => getAccountById(accountId!), [accountId]);

    return (
        <Form title={`Account: ${accountId}`} onSubmit={(data) => updateAccount(accountId!, data).then()} getData={getData}>
            <Section title="Details" cols={12}>
                <Field name="name" label="Name"><Input /></Field>
            </Section>
            <Section title="Billing Address" cols={12}>
                <Field label="Company" name="billingCompany"><Input /></Field>
                <Field label="Address 1" name="billingAddress1"><Input /></Field>
                <Field label="Address 2" name="billingAddress2"><Input /></Field>
                <Field label="City" name="billingCity"><Input /></Field>
                <Field label="State" name="billingState"><Input /></Field>
                <Field label="ZIP Code" name="billingZipCode"><Input /></Field>
            </Section>
        </Form>
    )
}
