import { Configuration, RedirectRequest, SilentRequest } from "@azure/msal-browser"

export const AuthConfig: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/organizations",
    clientId: "658b7961-d91e-47cd-b746-3546e915ad42", // Andosi Chat
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
}

export const AuthRequest: RedirectRequest = {
  scopes: [
    ".default"
  ],
  redirectStartPage: '/admin',
}

export const TokenRequest: SilentRequest = {
  scopes: [
    "api://36861fa0-c260-4615-9f07-c9744f18d63b/All", // Andosi Chat API
  ],
}
