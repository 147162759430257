import { useParams } from 'react-router';
import { Input } from 'antd';
import { Form, Section, Field } from 'components/Core/Form';
import { useCallback } from 'react';
import { getProviderById } from 'services/DataService';
import { PhoneNumberView } from 'screens/PhoneNumber/PhoneNumberView';

export const ProviderForm = () => {

    const { providerId } = useParams();

    const getData = useCallback(() => getProviderById(providerId!), [providerId]);

    return (
        <Form title={`Provider: ${providerId}`} getData={getData}>
            <Section title="Details" cols={12}>
                <Field name="name" label="Name"><Input readOnly /></Field>
                {/* <Field name="type" label="Type">
                    <Select>
                        <Select.Option key="managed" value="managed">Managed</Select.Option>
                        <Select.Option key="mobex" value="mobex">Mobex</Select.Option>
                        <Select.Option key="twilio" value="twilio">Twilio</Select.Option>
                    </Select>
                </Field>
                <Field name="isManaged" label="Is Managed?">
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Field>
                <Field name="username" label="Username"><Input /></Field>
                <Field name="password" label="Password"><Input /></Field> */}
            </Section>
            <Section title="Phone Numbers" cols={24}>
                <PhoneNumberView isQuickView />
            </Section>
        </Form>
    )
}