import Button from "antd/lib/button"
import Space from "antd/lib/space"

type CompletedStepProps = {
    onCompleted: () => void
}
export const CompletedStep = ({ onCompleted }: CompletedStepProps) => {
    return (
        <Space direction='vertical' size="large">
            <span>Welcome to Andosi Chat!</span>
            <Button type="primary" onClick={onCompleted}>Finish</Button>
        </Space>
    )
}