import { View } from 'components/Core/View'
import { Link } from 'react-router-dom'
import { getPhoneNumbers } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { PhoneNumberQuickForm } from './PhoneNumberQuickForm'
import { PhoneNumber } from 'services/types/PhoneNumber'

type PhoneNumberViewProps = {
    isQuickView?: boolean
}

export const PhoneNumberView = ({ isQuickView }: PhoneNumberViewProps) => {

    const columns: ColumnsType<PhoneNumber> = [
        {
            title: 'Value',
            dataIndex: 'value',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            render: (text, record) => <Link to={`/admin/providers/${record.providerId}`}>{text}</Link>,
        },
        {
            title: 'Environment',
            dataIndex: 'environment',
            render: (text, record) => <Link to={`/admin/environments/${record.environmentId}`}>{text}</Link>,
        },
    ];

    return (
        <View isQuickView={isQuickView} title="Phone Numbers" getData={getPhoneNumbers} columns={columns} newForm={PhoneNumberQuickForm} />
    )
}