import { View } from 'components/Core/View'
import { getMessageEvents, MessageEvent } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

export const MessageEventView = () => {

    const { messageId } = useParams();

    const columns: ColumnsType<MessageEvent> = [
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        }
    ];

    const getData = useCallback((data?: { [key: string]: string }) => {
        return getMessageEvents(messageId!, data)
    }, [messageId])

    return (
        <View title='Message Events' isQuickView columns={columns} getData={getData} />
    )
}