import Button from "antd/lib/button"
import Space from "antd/lib/space"

type WelcomeStepProps = {
    onCompleted: () => void
}

export const WelcomeStep = ({ onCompleted }: WelcomeStepProps) => (
    <Space direction='vertical' size="large">
        <span>Welcome to Andosi Chat!</span>
        <span>To get started, please click Next.</span>
        <Button type="primary" onClick={onCompleted}>
            Next
        </Button>
    </Space>
)