import { Select } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import Input from "antd/lib/input/Input";
import { Form, Section, Field } from "components/Core/Form";
import { useCallback, useState } from "react";
import { acquireProviderPhoneNumber, Environment, getEnvironments, getProviders } from "services/DataService";
import { PhoneNumber } from "services/types/PhoneNumber";
import { Provider } from "services/types/Provider";
import { PhoneNumberLookup } from "./PhoneNumberLookup";

type PhoneNumberQuickFormProps = {
    visible: boolean
    onClose: () => void
}

export const PhoneNumberQuickForm = ({ visible, onClose }: PhoneNumberQuickFormProps) => {

    const [environments, setEnvironments] = useState<Environment[]>()

    const [providers, setProviders] = useState<Provider[]>()
    const [providerId, setProviderId] = useState<string>()

    const getData = useCallback(() => {
        var environmentsPromise = getEnvironments().then(response => {
            setEnvironments(response.value)
            return response.value
        })
        var providersPromise = getProviders().then(response => {
            setProviders(response.value)
            return response.value
        })

        return Promise.all([environmentsPromise, providersPromise]).then(values => {
            var [environments, providers] = values;

            var phoneNumber: Partial<PhoneNumber> = {}
            if (environments.length === 1) {
                var environmentId = environments[0].id
                phoneNumber.environmentId = environmentId
            }
            if (providers.length === 1) {
                var providerId = providers[0].id
                phoneNumber.providerId = providerId
                setProviderId(providerId)
            }
            return phoneNumber
        })
    }, [])

    const [form] = useForm<PhoneNumber>()

    // Watch the phone number field to trigger a re-render, which will enabled the Save button
    useWatch('value', form);

    const onPhoneSelect = (phoneNumber?: string) => {
        form.setFieldsValue({ value: phoneNumber })
    }

    const footer = () => {
        return (
            <Section title="Search">
                <PhoneNumberLookup providerId={providerId} onSelect={onPhoneSelect} />
            </Section>
        )
    }

    return (
        <Form form={form} isQuickForm title="Add Phone Number" getData={getData} onSubmit={data => acquireProviderPhoneNumber(providerId!, data).then(onClose)} visible={visible} onClose={onClose} footer={footer()}>
            <Section title="Details">
                <Field name="providerId" label="Provider" rules={[{ required: true }]}>
                    <Select onChange={(value) => { setProviderId(value); form.setFieldsValue({ value: undefined }) }}>
                        {providers?.map(provider => <Select.Option key={provider.id} value={provider.id}>{provider.name}</Select.Option>)}
                    </Select>
                </Field>
                <Field name="environmentId" label="Environment" rules={[{ required: true }]}>
                    <Select>
                        {environments?.map(environment => <Select.Option key={environment.id} value={environment.id}>{environment.name}</Select.Option>)}
                    </Select>
                </Field>
                <Field name="value" label="Phone Number" rules={[{ required: true, message: 'Phone Number is required.' }]}>
                    <Input readOnly />
                </Field>
            </Section>
        </Form>
    )
}