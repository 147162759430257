import { Layout, Result } from "antd"

export const Error404 = () => {
    return (
        <Layout style={{ height: '100%', justifyContent: 'center' }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist." />
        </Layout>
    )
}