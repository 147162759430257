import { useParams } from 'react-router';
import { getEnvironmentById, createImport, updateEnvironment, syncEnvironment } from 'services/DataService'
import { Alert, Button, Input, Modal, Radio } from 'antd';
import { Form, Section, Field } from 'components/Core/Form';
import { useCallback, useState } from 'react';
import { ICommandBarItemProps } from '@fluentui/react';

export const EnvironmentForm = () => {

    const { environmentId } = useParams();

    const [loading, setLoading] = useState(false);
    const [showImportSolution, setShowImportSolution] = useState(false);

    const showModal = () => {
        setShowImportSolution(true);
    };

    const handleOk = () => {
        setLoading(true);
        createImport(environmentId!).then(() => {
            setLoading(false);
            setShowImportSolution(false);
        })
    };

    const handleCancel = () => {
        setShowImportSolution(false);
    };

    const actions: ICommandBarItemProps[] = [
        {
            key: "unlink",
            text: 'Unlink',
            iconProps: { iconName: 'RemoveLink' },
            onClick: () => console.log('unlink')
        },
        {
            key: "import",
            text: 'Import Solution',
            iconProps: { iconName: 'PageArrowRight' },
            onClick: showModal
        },
        {
            key: "sync",
            text: 'Sync',
            iconProps: { iconName: 'Sync' },
            onClick: () => { syncEnvironment(environmentId!) }
        },
    ];

    const getData = useCallback(() => getEnvironmentById(environmentId!), [environmentId]);

    return (
        <>
            <Form title={`Environment: ${environmentId}`} onSubmit={(data) => updateEnvironment(environmentId!, data).then()} getData={getData} actions={actions} getMessages={(environment) => {
                if (!environment.hasSolution) {
                    return (
                        <Alert type='warning' showIcon message='The solution has not been imported to this environment.' closable />
                    )
                }
            }}>
                <Section title="Details" cols={12}>
                    <Field name="name" label="Name" rules={[{ required: true, message: 'Please input your username!' }]}><Input /></Field>
                    <Field name="type" label="Type"><Input /></Field>
                    <Field name="url" label="URL"><Input /></Field>
                </Section>
                <Section title="Settings" cols={12}>
                    <Field name="allowChat" label="Allow Chat?">
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Field>
                    <Field name="allowText" label="Allow Text?">
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Field>
                </Section>
            </Form>
            <Modal
                visible={showImportSolution}
                centered
                title="Import Solution"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Yes
                    </Button>
                ]}>
                <p>This will import the Andosi Chat managed solution into this environment? Would you like to continue?</p>
            </Modal>
        </>
    )
}
