import { useParams } from 'react-router';
import { Input, Select } from 'antd';
import { Form, Section, Field } from 'components/Core/Form';
import { useCallback, useEffect, useState } from 'react';
import { Environment, getEnvironments, getPhoneNumber, releasePhoneNumber, updatePhoneNumber } from 'services/DataService';
import { PhoneNumber } from 'services/types/PhoneNumber';
import { ICommandBarItemProps } from '@fluentui/react/lib/components/CommandBar';

export const PhoneNumberForm = () => {

    const { phoneNumberId } = useParams();

    const [environments, setEnvironments] = useState<Environment[]>()

    useEffect(() => {
        getEnvironments().then(data => setEnvironments(data.value))
    }, [])

    const getData = useCallback(async () => {
        return await getPhoneNumber(phoneNumberId!)
    }, [phoneNumberId]);

    const setData = useCallback(async (data: PhoneNumber) => {
        await updatePhoneNumber(phoneNumberId!, data)
    }, [phoneNumberId])

    const actions: ICommandBarItemProps[] = [
        {
            key: "release",
            text: 'Release',
            iconProps: { iconName: 'Cancel' },
            onClick: () => { releasePhoneNumber(phoneNumberId!) }
        },
    ];

    return (
        <Form title={`Phone Number: ${phoneNumberId}`} getData={getData} onSubmit={setData} actions={actions} >
            <Section title="Details" cols={12}>
                <Field name="value" label="Value"><Input readOnly /></Field>
                <Field name="environmentId" label="Environment" rules={[{ required: true, message: 'Please choose an environment.' }]}>
                    <Select>
                        {environments?.map(environment => <Select.Option key={environment.id} value={environment.id}>{environment.name}</Select.Option>)}
                    </Select>
                </Field>
            </Section>
        </Form>
    )
}