import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import { useEffect, useState } from "react";
import { AvailableEnvironment, createEnvironment, createImport, getAvailableEnvironments } from "services/DataService";

type SelectEnvironmentStepProps = {
    onCompleted: () => void
}
export const SelectEnvironmentStep = ({ onCompleted }: SelectEnvironmentStepProps) => {

    const [availableEnvironments, setAvailableEnvironments] = useState<AvailableEnvironment[]>();
    const [environmentSaving, setEnvironmentSaving] = useState(false);
    const [environmentId, setEnvironmentId] = useState<string>();
    const [error, setError] = useState<string>()

    useEffect(() => {
        getAvailableEnvironments().then(data => setAvailableEnvironments(data.value))
    }, [])

    return (
        <Space direction='vertical'>
            <span>In order to get started, you must link a Power Apps environment to your account.</span>
            <Form layout='vertical' onFinish={async (data) => {
                setEnvironmentSaving(true);
                try {
                    const id = await createEnvironment(data);
                    if (!data.skipImport) {
                        await createImport(id)
                    }
                    onCompleted();
                }
                catch {
                    setError("There was an error creating the environment. Please try again.")
                }
                finally {
                    setEnvironmentSaving(false);
                }
            }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} style={{ paddingTop: 16 }}>
                        <Form.Item name="instanceId">
                            <Select placeholder="Please choose an environment..." onSelect={(value: string) => { setEnvironmentId(value) }}>
                                {availableEnvironments?.map(environment => <Select.Option key={environment.instanceId} value={environment.instanceId}>{environment.instanceName}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="skipImport" valuePropName='checked'>
                            <Checkbox>Skip import of the solution.</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Button type="primary" htmlType='submit' disabled={!environmentId} loading={environmentSaving} onClick={() => setError(undefined)}>Next</Button>
                        {error && <span className='error'>{error}</span>}
                    </Col>
                </Row>
            </Form>
        </Space>
    )
}