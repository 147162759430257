import Button from 'antd/lib/button';
import { useNavigate } from 'react-router';
import './Home.css'

export const Home = () => {

    const navigate = useNavigate()

    return (
        <div className="landing">
            <div className="header">
                <span style={{ fontSize: 24, color: 'white', fontWeight: 600 }}></span>
                <div>
                    <Button type="ghost" style={{ color: 'white', borderWidth: 2 }} onClick={() => navigate('/admin')}>Login</Button>
                </div>
            </div>
            <div className="banner">
                <div className="spacer">
                    <h1><b>Andosi</b> Chat</h1>
                    <h2>Coming soon.</h2>
                </div>
            </div>
        </div>
    )
}