import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from './NavigationClient'
import { MsalProvider } from "@azure/msal-react"
import { useNavigate } from "react-router";
import axios from "axios";
import { TokenRequest } from "./AuthConfig";


type AuthProviderProps = {
    instance: PublicClientApplication
    children: React.ReactNode
}

export const AuthProvider = ({ instance, children }: AuthProviderProps) => {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    instance.setNavigationClient(navigationClient);

    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(instance.getAllAccounts()[0]);
    }

    instance.enableAccountStorageEvents();

    instance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            instance.setActiveAccount(account);
        }
    });

    axios.defaults.baseURL = (process.env.REACT_APP_API_URL ?? window.location.origin) + "/api/v1.0"
    axios.defaults.headers.common['Content-Type'] = "application/json"
    axios.defaults.timeout = 30000
    axios.interceptors.request.use(
        async (config) => {
            const response = await instance.acquireTokenSilent(TokenRequest)
            if (response) {
                config.headers!["Authorization"] = `Bearer ${response.accessToken}`
            }
            config.headers!["X-AccountId"] = window.localStorage.getItem("accountId") ?? ''
            config.headers!["Content-Type"] = "application/json"
            return config
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return (
        <MsalProvider instance={instance}>
            {children}
        </MsalProvider>
    )
}