import "chart.js/auto";
import 'chartjs-adapter-date-fns';
import { Bar } from "react-chartjs-2";
import { MessageSummary } from "services/types";
import parse from "date-fns/parse";
import { ChartOptions } from "chart.js/auto";

type MessagesChartProps = {
    grouping: 'daily' | 'monthly'
    min?: Date,
    max?: Date,
    messages: MessageSummary[]
}

export const MessagesChart = ({ grouping: aggregation, min, max, messages }: MessagesChartProps) => {

    const options: ChartOptions<"bar"> = {
        plugins: {
            legend: {
                position: 'bottom'
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    maxRotation: 0,
                },
                type: 'time',
                time: {
                    unit: aggregation === 'daily' ? 'day' : 'month',
                    round: aggregation === 'daily' ? 'day' : 'month',
                },
                min: min?.getTime(),
                max: max?.getTime(),
            },
            y: {
                stacked: true,
                ticks: {
                    precision: 0,
                },
            },
        }
    }

    const data = {
        datasets: [
            {
                label: 'Incoming',
                data: messages.map(m => {
                    return {
                        x: parse(m.group, aggregation === 'daily' ? 'yyyy-MM-dd' : 'yyyy-MM', new Date(0)),
                        y: m.incoming,
                    }
                }),
                backgroundColor: '#30d4a0'
            },
            {
                label: 'Outgoing',
                data: messages.map(m => {
                    return {
                        x: parse(m.group, aggregation === 'daily' ? 'yyyy-MM-dd' : 'yyyy-MM', new Date(0)),
                        y: m.outgoing,
                    }
                }),
                backgroundColor: '#3064d4'
            }
        ]
    }

    return (
        <Bar options={options} data={data} />
    )
}