import { useParams } from 'react-router';
import { getMessageById } from 'services/DataService';
import { Input, InputNumber } from 'antd'
import { Form, Section, Field } from 'components/Core/Form';
import { MessageEventView } from './MessageEventView';

export const MessageForm = () => {

    const { messageId } = useParams();

    return (
        <Form title={`Message: ${messageId}`} getData={() => getMessageById(messageId!)}>
            <Section title="Details" cols={12}>
                <Field name="source" label="From"><Input readOnly bordered={false} /></Field>
                <Field name="target" label="To"><Input readOnly bordered={false} /></Field>
                <Field name="status" label="Status"><Input readOnly bordered={false} /></Field>
                <Field name="content" label="Content"><Input readOnly bordered={false} style={{ fontFamily: 'Consolas,monospace', backgroundColor: '#f0f2f5' }} /></Field>
            </Section>
            <Section title="Pricing" cols={12}>
                <Field name="direction" label="Direction"><Input readOnly bordered={false} /></Field>
                <Field name="segments" label="Segments"><Input readOnly bordered={false} /></Field>
                <Field name="encoding" label="Encoding"><Input readOnly bordered={false} /></Field>
                <Field name="billingUnits" label="Price"><InputNumber readOnly prefix="$" precision={2} bordered={false} /></Field>
            </Section>
            <Section title="Events" cols={24}>
                <MessageEventView />
            </Section>
        </Form>
    )
}