import { useParams } from 'react-router';
import { getUserById, updateUser, User } from 'services/DataService';
import { Form, Section, Field } from 'components/Core/Form';
import { useCallback } from 'react';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';

export const UserForm = () => {

    const { userId } = useParams();

    const getData = useCallback(async () => {
        return await getUserById(userId!)
    }, [userId])

    const setData = useCallback(async (data: User) => {
        await updateUser(userId!, data)
    }, [userId])

    return (
        <Form title={`User: ${userId}`} getData={getData} onSubmit={setData}>
            <Section title="Details" cols={12}>
                <Field name="name" label="Name"><Input /></Field>
                <Field name="email" label="Email"><Input /></Field>
                <Field name="role" label="Role">
                    <Select style={{ width: '100%' }}>
                        <Select.Option key="owner" value="owner">Owner</Select.Option>
                        <Select.Option key="admin" value="admin">Admin</Select.Option>
                        <Select.Option key="reader" value="reader">Reader</Select.Option>
                    </Select>
                </Field>
            </Section>
        </Form>
    )
}