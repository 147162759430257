import { useEffect, useState } from 'react';
import { Steps, Card, Row, Col, PageHeader } from 'antd';
import { useAppContext } from 'components/Core/Base';
import { Content } from 'antd/lib/layout/layout';
import { CompletedStep, CreateAccountStep, SelectEnvironmentStep, SelectPhoneNumberStep, WelcomeStep } from './Steps';

const { Step } = Steps;

export const SetupDashboard = () => {

    const { context, refresh } = useAppContext();
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (context?.hasAccounts) {
            setCurrentStep(2);
        }
        if (context?.hasEnvironments) {
            setCurrentStep(3);
        }
        if (context?.hasPhoneNumbers) {
            setCurrentStep(4);
        }
    }, [context?.hasAccounts, context?.hasEnvironments, context?.hasPhoneNumbers])

    const steps = [
        {
            title: 'Welcome',
            content: () => (<WelcomeStep onCompleted={() => { setCurrentStep(1) }} />),
        },
        {
            title: 'Create an account',
            content: () => (<CreateAccountStep onCompleted={() => refresh()} />),
        },
        {
            title: 'Link an environment',
            content: () => (<SelectEnvironmentStep onCompleted={() => refresh()} />),
        },
        {
            title: 'Select a phone number',
            content: () => (<SelectPhoneNumberStep onCompleted={() => setCurrentStep(4)} />),
        },
        {
            title: 'Finish',
            content: () => (<CompletedStep onCompleted={() => refresh()} />),
        },
    ];

    return (
        <>
            <PageHeader title="Setup" />
            <Content style={{ padding: '16px 24px' }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card title="Getting Started">
                            <Steps current={currentStep} direction="vertical">
                                {steps.map((item, x) => (
                                    <Step key={item.title} title={item.title} description={<Content style={{ padding: '16px 0' }}>{currentStep === x ? item.content() : null}</Content>} />
                                ))}
                            </Steps>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </>
    )
}