import React from 'react'
import { View } from 'components/Core/View'
import { Link } from 'react-router-dom'
import { getProviders } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { Provider } from 'services/types/Provider'

export const ProviderView = () => {

    const columns: ColumnsType<Provider> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'Password',
            dataIndex: 'password',
        },
        {
            title: 'Managed?',
            dataIndex: 'isManaged',
            render: (text, record) => <>{record.isManaged ? 'True' : 'False'}</>,
        },
    ];

    return (
        <View title="Providers" getData={getProviders} columns={columns} />
    )
}