import { Routes, Route } from 'react-router-dom';
import { Base } from 'components/Core/Base'
import {
  MessageView,
  MessageForm,
  Dashboard,
  EnvironmentView,
  EnvironmentForm,
  Error404,
  UserView,
  AccountView,
  Home,
  PhoneNumberView,
  Login,
  AccountForm,
} from 'screens'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import './App.less'
import { PhoneNumberForm } from 'screens/PhoneNumber/PhoneNumberForm';
import { ProviderView } from 'screens/Provider/ProviderView';
import { ProviderForm } from 'screens/Provider/ProviderForm';
import { UserForm } from 'screens/User/UserForm';

export const App = () => {

  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Base />}>
            <Route index element={<Dashboard />} />
            <Route path="accounts">
              <Route index element={<AccountView />} />
              <Route path=":accountId" element={<AccountForm />} />
            </Route>
            <Route path="messages">
              <Route index element={<MessageView />} />
              <Route path=":messageId" element={<MessageForm />} />
            </Route>
            <Route path="environments">
              <Route index element={<EnvironmentView />} />
              <Route path=":environmentId" element={<EnvironmentForm />} />
            </Route>
            <Route path="providers">
              <Route index element={<ProviderView />} />
              <Route path=":providerId">
                <Route index element={<ProviderForm />} />
              </Route>
            </Route>
            <Route path="phoneNumbers">
              <Route index element={<PhoneNumberView />} />
              <Route path=":phoneNumberId">
                <Route index element={<PhoneNumberForm />} />
              </Route>
            </Route>
            <Route path="users">
              <Route index element={<UserView />} />
              <Route path=":userId">
                <Route index element={<UserForm />} />
              </Route>
            </Route>
            <Route path="*" element={<Error404 />} />
          </Route>
          <Route path="*" element={<Error404 />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/admin">
            <Route index element={<Login />} />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
}
