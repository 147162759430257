import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { AuthRequest } from "services/auth";

export const Login = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.handleRedirectPromise().then(() => {
            const account = instance.getActiveAccount();
            if (!account) {
                instance.loginRedirect(AuthRequest);
            }
        })
    }, [instance])

    return (
        <></>
    )
}