import { View } from 'components/Core/View'
import { Environment, getEnvironments, getMessages, Message } from 'services/DataService'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { MessageQuickForm } from './MessageQuickForm'
import { useEffect, useState } from 'react'

export const MessageView = () => {

    const [environments, setEnvironments] = useState<Environment[]>();

    useEffect(() => {
        getEnvironments().then(data => {
            setEnvironments(data.value);
        })
    }, [])

    const columns: ColumnsType<Message> = [
        {
            title: 'Timestamp',
            dataIndex: 'actualStart',
            render: (text, record) => <Link to={record.id}>{text}</Link>,
            width: 250
        },
        {
            title: 'Environment',
            dataIndex: 'environmentId',
            render: (text, record) => <Link to={"/environments/" + record.environmentId}>{record.environment}</Link>,
            width: 250,
            filters: environments?.map((environment => {
                return {
                    text: environment.name,
                    value: environment.id,
                }
            }))
        },
        {
            title: 'Type',
            dataIndex: 'type',
            filters: [
                {
                    text: 'Chat',
                    value: 'Chat',
                },
                {
                    text: 'Text',
                    value: 'Text',
                },
            ],
            width: 100
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            filters: [
                {
                    text: 'Incoming',
                    value: 'Incoming',
                },
                {
                    text: 'Outgoing',
                    value: 'Outgoing',
                },
            ],
            width: 115
        },
        {
            title: 'From',
            dataIndex: 'source',
            width: 150,
        },
        {
            title: 'To',
            dataIndex: 'target',
            width: 150,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                {
                    text: 'Created',
                    value: 'Created',
                },
                {
                    text: 'Queued',
                    value: 'Queued',
                },
                {
                    text: 'Completed',
                    value: 'Completed',
                },
            ],
            width: 100,
        }
    ];

    return (
        <View title='Messages' columns={columns} getData={getMessages} newForm={MessageQuickForm} />
    )
}