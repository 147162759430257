import Select from "antd/lib/select";
import { Form, Section, Field } from "components/Core/Form";
import { useCallback, useEffect, useState } from "react";
import { createUser, getAvailableUsers, User } from "services/DataService";

type UserQuickFormProps = {
    visible: boolean
    onClose: () => void
}

export const UserQuickForm = ({ visible, onClose }: UserQuickFormProps) => {

    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        getAvailableUsers().then(x => {
            setUsers(x.value)
        })
    }, [])

    const setData = useCallback(async (data: User) => {
        await createUser(data)
        onClose()
    }, [onClose])

    return (
        <Form isQuickForm title="Invite User" onSubmit={setData} visible={visible} onClose={onClose}>
            <Section title="Details">
                <Field name="username" label="User" rules={[{ required: true }]}>
                    <Select showSearch style={{ width: '100%' }}>
                        {users?.map(user => <Select.Option key={user.username} value={user.username}>{user.name}</Select.Option>)}
                    </Select>
                </Field>
                <Field name="role" label="Role" rules={[{ required: true }]}>
                    <Select style={{ width: '100%' }}>
                        <Select.Option key="owner" value="owner">Owner</Select.Option>
                        <Select.Option key="admin" value="admin">Admin</Select.Option>
                        <Select.Option key="reader" value="reader">Reader</Select.Option>
                    </Select>
                </Field>
            </Section>
        </Form>
    )
}